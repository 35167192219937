
import { defineAsyncComponent, shallowRef } from 'vue'
import { mapGetters, mapState } from 'vuex'
import PDEBadge from '@/components/pde/PDEBadge.vue'

export default {
  name: 'CredentialingContent',

  components: {
    Error: defineAsyncComponent(() => import('@/error')),
    PDEHeading: defineAsyncComponent(() => import('@/components/pde/PDEHeading.vue')),
    PDEHorizontalNav: defineAsyncComponent(() => import('@/components/pde/PDEHorizontalNav.vue')),
  },

  props: {
    pathPrefix: {
      type: String,
      require: true,
      default: '',
    },
  },

  data({ pathPrefix }) {
    return {
      isAnimatingUploadedBadge: false,
      requestsLink: {
        to: `${pathPrefix}/documents`,
        label: 'Requests',
        dataTest: 'documents-index',
        analyticsData: {
          clickName: 'Tab Clicked',
          clickText: 'Requests',
          clickArea: `${pathPrefix}/documents/credentialing/requests`,
        },
      },
      uploadedLink: {
        to: `${pathPrefix}/documents/uploaded`,
        label: 'Uploaded',
        dataTest: 'documents-uploaded',
        analyticsData: {
          clickName: 'Tab Clicked',
          clickText: 'Uploaded',
          clickArea: `${pathPrefix}/documents/credentialing/uploaded`,
        },
      },
    }
  },

  computed: {
    ...mapGetters('documents', ['documentError', 'received', 'requested']),
    ...mapState('documents', ['isLoading']),

    numRequestedReceived() {
      return {
        numReceived: this.received.length,
        numRequested: this.requested.length,
      }
    },
  },

  watch: {
    numRequestedReceived: {
      handler(newValue, oldValue) {
        // A successful upload results in a simultaneous change of requested and received values
        // will trigger the uploaded badge animation
        if (newValue.numRequested < oldValue.numRequested && newValue.numReceived > oldValue.numReceived) {
          this.animateUploadedBadge()
        }
      },
      deep: true,
    },

    received: {
      handler() {
        if (this.isAnimatingUploadedBadge) return

        this.updateUploadedBadge()
      },
      immediate: true,
    },

    requested: {
      handler() {
        this.updateRequestsBadge()
      },
      immediate: true,
    },
  },

  methods: {
    async animateUploadedBadge() {
      this.isAnimatingUploadedBadge = true

      // Display the uploaded badge with the default badge styles for 2 seconds
      this.updateBadge({
        classes: 'transition-colors duration-300',
        type: 'uploaded',
        value: this.received.length,
      })

      await new Promise(resolve => setTimeout(resolve, 2000))

      // Animate the uploaded badge back to its initial state
      this.updateUploadedBadge({ classes: 'transition-all duration-500' })
      this.isAnimatingUploadedBadge = false
    },

    updateBadge({ bgColor, borderColor, classes, textColor, type, value }) {
      const linkId = `${type}Link`

      this[linkId] = {
        ...this[linkId],
        child: value
          ? {
              component: shallowRef(PDEBadge),
              props: {
                bgColor,
                borderColor,
                class: [classes || 'animate-fade-in delay-500', 'ml-8'],
                textColor,
                value,
              },
            }
          : null,
      }
    },

    updateRequestsBadge() {
      this.updateBadge({
        bgColor: 'bg-pureblack-0',
        textColor: 'text-secondary-500',
        type: 'requests',
        value: this.requested.length,
      })
    },

    updateUploadedBadge({ classes } = {}) {
      this.updateBadge({
        bgColor: 'bg-pureblack-5',
        borderColor: 'border-pureblack-5',
        classes,
        textColor: 'pureblack-45',
        type: 'uploaded',
        value: this.received.length,
      })
    },
  },
}
