import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  "data-test": "horizontal-nav",
  class: "py-16 border-solid border-b-1 border-pureblack-10 items-center bg-pureblack-0"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "relative flex xs:gap-16 sm:gap-24 md:gap-32 inline-flex",
      onMouseleave: _cache[1] || (_cache[1] = (...args) => ($options.resetHover && $options.resetHover(...args)))
    }, [
      _createElementVNode("div", {
        "data-test": "hover-background",
        class: "absolute bottom-0 h-full opacity-50 bg-primary-50 transition-all duration-500 rounded-3",
        style: _normalizeStyle([$data.hoverStyles, $options.hoverHeight]),
        onTransitionend: _cache[0] || (_cache[0] = (...args) => ($options.resetHoverAfterTransition && $options.resetHoverAfterTransition(...args)))
      }, null, 36),
      _createElementVNode("div", {
        "data-test": "selected-background",
        class: "absolute bottom-0 h-full bg-primary-50 transition-all duration-500 rounded-3",
        style: _normalizeStyle([$data.selectedStyles, $options.selectedHeight])
      }, null, 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.items, (item, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: item.to,
          ref_for: true,
          ref: item.to,
          to: item.to,
          "aa-click-name": $options.remappedAnalyticsData(item).name,
          "aa-click-text": $options.remappedAnalyticsData(item).text,
          "aa-click-area": $options.remappedAnalyticsData(item).area,
          class: _normalizeClass(["relative flex text-14 p-12 leading-6 no-underline whitespace-nowrap", [$options.activeClasses(item.to)]]),
          "data-test": item.dataTest,
          onMouseover: $event => ($options.moveHover(item.to))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.label) + " ", 1),
            (item.child && item.child.component)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.child && item.child.component), _mergeProps({
                  key: 0,
                  ref_for: true
                }, item.child && item.child.props, {
                  "data-test": `${item.dataTest || `item-${index}`}-child`
                }), null, 16, ["data-test"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to", "aa-click-name", "aa-click-text", "aa-click-area", "class", "data-test", "onMouseover"]))
      }), 128))
    ], 32)
  ]))
}